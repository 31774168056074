import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PageWrapper from '../PageWrapper/PageWrapper';

const LayoutWrapper = ({ children, isAuth, visibleSidebar, showDivZap }) => (
  <div className="layout">
    {isAuth && <Sidebar visibleSidebar={visibleSidebar} />}
    {!isAuth && <Header isAuth={isAuth} visibleSidebar={visibleSidebar} />}
    <main>
      <PageWrapper showDivZap={showDivZap}>{children}</PageWrapper>
    </main>
    <Footer />
  </div>
);

export default LayoutWrapper;
