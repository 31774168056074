import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 82%;
  padding: 50px 30px;

  @media (max-width: 850px) {
    width: calc(100% - 155px);
  }

  @media (max-width: 465px) {
    padding: 40px 20px;
  }
  
  @media (max-width: 425px) {
    width: calc(100% - 135px);
  }
`;

export const DivBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 44px;

  @media (max-width: 700px) {
    font-size: 32px;
  }

  @media (max-width: 575px) {
    font-size: 24px;
  }
`;

export const DivImage = styled.div`
  border: 1px solid var(--black);
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
  width: 60px;

  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    height: 50px;
    width: 50px;

    img {
      height: 50px;
      width: 50px;
    }
  }

  @media (max-width: 450px) {
    height: 40px;
    width: 40px;

    img {
      height: 40px;
      width: 40px;
    }
  }
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  margin-top: 2rem;
`;

export const DivRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`;