import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  padding: 50px 10px;
  width: 90%;
`;

export const Title = styled.h2`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 40px;
  font-weight: 800;
  line-height: 41px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 568px) {
    justify-content: center;
  }
`;

export const DivColummn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 23%;
`;

export const LargeImage = styled.div`
    border: 1px solid var(--black);
    border-radius: 20px;
    height: 565px;
    margin: 8px;
    overflow: hidden;
    width: 270px;
    
    img {
        border-radius: 20px;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    @media (max-width: 1250px) {
      width: 250px;
    }

    @media (max-width: 1150px) {
      height: 470px;
      width: 230px;
    }

    @media (max-width: 1050px) {
      height: 430px;
      width: 200px;
    }

    @media (max-width: 900px) {
      height: 565px;
      width: 320px;
    }

    @media (max-width: 768px) {
      width: 290px;
    }

    @media (max-width: 702px) {
      width: 260px;
    }

    @media (max-width: 635px) {
      width: 230px;
    }

    @media (max-width: 568px) {
      width: 270px;
    }
`;

export const SmallImage = styled.div`
    border: 1px solid var(--black);
    border-radius: 20px;
    height: 380px;
    margin: 8px;
    overflow: hidden;
    width: 270px;

    img {
        border-radius: 20px;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    @media (max-width: 1250px) {
      width: 250px;
    }
    
    @media (max-width: 1150px) {
      height: 280px;
      width: 230px;
    }

    @media (max-width: 1050px) {
      height: 250px;
      width: 200px;
    }

    @media (max-width: 900px) {
      height: 380px;
      width: 320px;
    }

    @media (max-width: 768px) {
      width: 290px;
    }

    @media (max-width: 702px) {
      width: 260px;
    }

    @media (max-width: 635px) {
      width: 230px;
    }

    @media (max-width: 568px) {
      width: 270px;
    }
`;
