import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: 100%;

    img {
        mix-blend-mode: multiply;
        margin-bottom: 30px;
    }
`;

export const Title = styled.h1`
    font-family: Inconsolata;
    margin-bottom: 40px;
`;