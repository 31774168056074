import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  width: 90vw;
  align-self: center;
`;

export const ClientProfileDetails = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  min-width: 450px;
  color: var(--purple);
  margin-bottom: 3rem;

  h2 {
    margin-bottom: -5px;
  }
  h4 {
    margin-top: 8px;
  }
  a {
    color: var(--mediumgray);
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
    :hover {
      color: var(--darkgray);
    }
  }

  span {
    color: var(--mediumgray);
    font-size: 14px;
    margin-left: 5px;
  }

  .image-container {
    box-sizing: border-box;
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: relative;

    & > img {
      width: 200px;
      height: 200px;
      border-radius: 100px; 
    }

    .logo {
      color: white;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 18px;
      bottom: 0px;
      background: var(--purple);
      padding: 10px;
      border-radius: 50%;
    }
  }
  
  @media (max-width: 675px) {
    width: 100%;
  }

  @media (max-width: 490px) {
    flex-direction: column;
    min-width: inherit;

    & > div:nth-of-type(1) {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    & > div:nth-of-type(2) {
      margin-left: 20px;
    }
  }
`;

export const ClientSolicitations = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--purple);
    padding-bottom: 10px;
  }

  .solicitation {
    border-bottom: 2px solid var(--gray);
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 1.5rem;

    & > button {
      width: 250px;
      margin: 0;
      border: 0.5px solid var(--gray);

      background: var(--purple);
      padding: 0.5rem;
      cursor: pointer;

      & > h2 {
        color: var(--white);
      }
    }

    & > .active {
      background: var(--brightpurple);
    }
  }

  .searchIcon {
    margin-right: 10px;
    color: var(--mediumgray);
  }

  .searchPlace {
    display: flex;
    width: 100%;
    max-width: 90%;
    justify-content: space-around;

    @media (max-width: 475px) {
      max-width: 100%;
    }
  }

  .inputPlace {
    border: 1px solid var(--gray);
    border-radius: 18px;
    padding: 10px 14px 10px 24px;
    display: flex;
    align-items: center;
    input {
      width: 20rem;
      max-width: 90%;
      border: none;
      ::placeholder {
        color: var(--gray);
        font-family: "Poppins", sans-serif;
        font-size: 12px;
      }
    }
  }

  .solicitationsType {
    /* background-color: red; */
    width: 100%;

    display: flex;
    justify-content: center;

    & > button {
      margin: 0;
      border: 0.5px solid var(--gray);
      background: var(--purple);
      padding: 1rem;
      cursor: pointer;

      & > span {
        color: var(--white);
      }
    }

    & > .active {
      background: var(--brightpurple);
    }
  }

  .btnFilter {
    padding: 8px 20px;
    border-radius: 18px;
    border: 1px solid var(--gray);
    background-color: transparent;
    color: var(--purple);
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 10px;
    min-width: 110px;

    button {
      background-color: transparent;
      border: none;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    padding: 0 10px;

    .inputPlace {
      padding: 8px 10px;
      max-width: 70%;
    }
  }
`;

export const Solicitations = styled.div`
  margin: 3rem 0;

  .influencerSolicitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--gray);

    img {
      border: 1px solid var(--mediumgray);
      border-radius: 50%;
      cursor: pointer;
      height: 80px;
      width: 80px;
      object-fit: cover;
    }
    div {
      text-align: center;
      .solicitationStatus {
        font-weight: 600;
      }

      span {
        color: var(--mediumgray);
        font-size: 14px;
      }
      p {
        margin-top: 10px;
      }
    }
    button {
      border: none;
      border-radius: 10px;
      padding: 10px 14px;
      font-weight: 600;
      cursor: no-drop;
      :hover {
        /* border: 2px solid var(--black); */
      }
    }

    button:disabled {
      border: 1px solid var(--mediumgray);
    }

    .emptySpace {
      width: 60px;
    }

    .primary {
      background: var(--brightpurple);
      color: var(--white);
      cursor: pointer;
    }

    @media (max-width: 675px) {
      width: 100%;
    }

    @media (max-width: 582px) {
      flex-wrap: wrap;

      & > :nth-child(1n) {
        margin-bottom: 10px;
      }

      & > div:nth-child(2n) {
        min-width: 140px;
      }
    }
  }
`;

export const ContentModal = styled.div`
  border: 1px solid #b4b3b354;
  border-radius: 8px;
`

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px;

  & > img {
    margin-right: 10px;
  }

  & > h3 {
    color: var(--brightpurple);
    margin-right: 8px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  button {
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 14px 30px;

    & > img {
      margin-right: 10px;
    }
  }

  .secondary {
    background: var(--lightpurple);
    color: var(--black);
  }

  .primary {
    background: var(--brightpurple);
    color: var(--white);
  }

  .danger {
    background: var(--red);
    color: var(--white);
  }
`

export const Button = styled.button`
  background-color: var(--brightpurple);
  border: none;
  border-radius: 10px;
  color: var(--white);
  display: flex;
  align-items: center;
  padding: 14px 30px;

  &:hover:not(:disabled) {
    background-color: var(--purple);
    color: var(--white);
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.6;
    cursor: no-drop;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--purple);
  border-radius: 8px;
  margin-top: 20px;
  height: 200px;
  width: 48%;
  padding: 8px 10px;
  
  & > h2 {
    text-align: center;
  }

  & span {
    font-weight: 600;
  }

  & > p {
    margin-bottom: 8px;
  }
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > h2 {
    color: var(--brightpurple);
    font-size: 16px;
  }

  & > h3 {
    color: var(--brightpurple);
  }
`

export const HR = styled.hr`
  background: #b4b3b354;
  height: 1px;
  width: 100%;
`

export const Loading = styled.div`
  border: 4px solid var(--mediumgray);
  border-top: 4px solid var(--brightpurple);
  border-radius: 50%;
  margin-left: 10px;
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  height: 30px;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`