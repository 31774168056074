import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import InfluencerCard from "../../components/InfluencerCard/InfluencerCard";
import { BsSliders } from "react-icons/bs";
import { Card, Container, Content, DivBetween, DivColumn, DivIcon, DivImage, InfluencersList, Main, Title } from "./styles";
import api from "../../services";
import { useAuth } from "../../providers/auth";
import { useClient } from '../../providers/user';
import ImgUser from '../../assets/user.webp';
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import { regioes } from "../../utils/helpers";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Sidebar from "../../components/Sidebar/Sidebar";
import { toast } from "react-toastify";
import Target from "../../assets/target.svg";
import Ampulheta from "../../assets/time.svg";
import Hour from "../../assets/hour.svg";
import Checked from "../../assets/checked.svg";


const animatedComponents = makeAnimated();

// let typingTimeout;

const Dashboard = () => {
  const { auth } = useAuth();
  const { userData } = useClient();
  //usando ref para não perde os valores quando o listner do scroll for criado.
  const totalPages = useRef();
  const page = useRef(1);
  const isLoading = useRef(false);
  const searchInfluencerRef = useRef("");
  const stateSelectedRef = useRef("");
  const citySelectedRef = useRef("");
  const idSegmentRef = useRef([]);
  const typingTimeoutRef = useRef();

  const history = useHistory();
  const [influencers, setInfluencers] = useState();
  const [listToShow, setListToShow] = useState([]);
  const [filterType, setFilterType] = useState("zone");
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [uf, setUf] = useState([]);
  const [city, setCity] = useState([]);
  const [segment, setSegment] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [visibleSidebar, setVisibleSidebar] = useState(true)
  const [countCampanhas, setCountCampanhas] = useState();

  // useEffect(() => {
  //   showInfluencers();
  // }, [trigger]);

  useEffect(() => {
    if (userData) {
      getCountCampanhas(userData.id);
    }
  }, [userData])

  useEffect(() => {
    // setFilterType("segment");
  }, [citySelectedRef.current]);

  const getCountCampanhas = async (id) => {
    try {
      const result = await api.get("api/v1/campanhas/count/", {
        params: {
          cliente: id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })

      if (result) {
        const { data } = result;
        setCountCampanhas(data);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.details) {
        toast.error(`${error.response.data.details}`);
      } else {
        toast.error(`${error}`);
      }
    }
  }

  const filterFromInput = (e) => {
    if (e === "") {
      searchInfluencerRef.current = e;
      setTrigger((prev) => !prev);
      return;
    }
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      searchInfluencerRef.current = e;
      setTrigger((prev) => !prev);
    }, [500]);
  };

  const filterState = (_estado) => {
    stateSelectedRef.current = _estado;
    setCity(uf.find((e) => e.nome === _estado).cidades);
  };

  const cleanFilters = (status) => {
    // setFilterType("");
    stateSelectedRef.current = "";
    citySelectedRef.current = "";
    idSegmentRef.current = [];
    setTrigger((prev) => !prev);
    setShouldOpenModal(false);
  };

  const showInfluencers = async (_page = 1) => {
    if (!isLoading.current) {
      isLoading.current = true;
      try {
        const params = {
          page: _page,
          ativo: true,
          search:
            searchInfluencerRef.current.length > 2
              ? searchInfluencerRef.current
              : null,
          estado: stateSelectedRef.current,
          cidade: citySelectedRef.current,
        };
        let url = "/api/v1/influencers/";
        idSegmentRef.current.forEach((id, index) => {
          if (index === 0) {
            url += `?segmentos_order=${id}`;
          } else {
            url += `&segmentos_order=${id}`;
          }
        });
        const response = await api
          .get(`${url}`, {
            params: {
              ...params,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth}`,
            },
          })
          .then((response) => {
            const total =
              response.data.count % 10 === 0
                ? response.data.count / 10
                : Math.floor(response.data.count / 10) + 1;
            // setTotalPages(total);
            totalPages.current = total;

            if (_page === 1) {
              setListToShow(response.data.results);
              page.current = 1;
            } else {
              setListToShow((old) => [...old, ...response.data.results]);
              page.current = _page;
            }

            setShouldOpenModal(false);
          });
      } finally {
        isLoading.current = false;
      }
    }
  };

  const showStates = () => {
    let ufs = [];
    regioes.regioes.map((item) => ufs.push(...item.estados));
    setUf(ufs);
  };

  const showSegment = async () => {
    const response = await api
      .get(`/api/v1/segmentos/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        const _segments = response.data.map((item) => {
          return {
            id: item.id,
            nome: item.nome,
            value: item.nome,
            label: item.nome,
            taxa_percentual: item.taxa_percentual,
          };
        });
        setSegment(_segments);
      });
    return response;
  };

  const handleIdSegment = (id) => {
    const _ids = id.map((item) => item.id);
    idSegmentRef.current = _ids;
  };

  const handleCitySelected = (city) => {
    citySelectedRef.current = city;
  };

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 >=
      e.target.documentElement.scrollHeight
    ) {
      if (page.current < totalPages.current) {
        showInfluencers(page.current + 1);
      }
    }
  };

  const renderInfluencers = (list) => {
    return list.map((influencer) => {
      let socialMedia = influencer.instagram;
      let qtdSocialMedia = influencer.qtd_intagram;

      if (influencer.redesocial_principal === "Tiktok") {
        socialMedia = influencer.tiktok;
        qtdSocialMedia = influencer.qtd_tiktok;
      } else if (influencer.redesocial_principal === "YouTube") {
        socialMedia = influencer.youtube;
        qtdSocialMedia = influencer.qtd_youtube;
      }

      return (
        <InfluencerCard
          key={influencer.id}
          image={influencer.profile_picture}
          influencer={influencer}
          name={influencer.nome}
          socialMedia={socialMedia}
          followers={qtdSocialMedia}
        />
      );
    });
  };

  useEffect(() => {
    if (!auth) {
      return history.push("/");
    }
    // showInfluencers();
    // showStates();
    // showSegment();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setVisibleSidebar(!visibleSidebar);
  }

  return (
    <Container logged={auth ? true : false}>
      {/* <Sidebar
        visibleSidebar={visibleSidebar} 
        toggleSidebar={toggleSidebar}
        /> */}
      <Main visibleSidebar={visibleSidebar}>
        <DivBetween>
          <Title>Dashboard</Title>
          <DivImage>
            <img src={userData && userData.profile_picture ? userData.profile_picture : ImgUser} alt='Imagem do usuário' height={60} width={60} />
          </DivImage>
        </DivBetween>
        <Content>
          <Card>
            <DivBetween>
              <DivIcon>
                <img src={Target} />
              </DivIcon>
              <DivColumn>
                <p>Campanhas Publicadas</p>
                <span>{countCampanhas?.total_campanhas || 0}</span>
              </DivColumn>
            </DivBetween>
          </Card>
          <Card>
            <DivBetween>
              <DivIcon>
                <img src={Ampulheta} alt="Imagem de uma ampulheta" />
              </DivIcon>
              <DivColumn>
                <p>Aguardando Sua Resposta</p>
                <span>{countCampanhas?.campanhas_aguardando_pagamento || 0}</span>
              </DivColumn>
            </DivBetween>
          </Card>
          <Card>
            <DivBetween>
              <DivIcon>
                <img src={Hour} />
              </DivIcon>
              <DivColumn>
                <p>Campanhas Recusadas</p>
                <span>{countCampanhas?.campanhas_recusadas || 0}</span>
              </DivColumn>
            </DivBetween>
          </Card>
          <Card>
            <DivBetween>
              <DivIcon>
                <img src={Checked} alt="" />
              </DivIcon>
              <DivColumn>
                <p>Campanhas Concluídas</p>
                <span>{countCampanhas?.campanhas_concluidas || 0}</span>
              </DivColumn>
            </DivBetween>
          </Card>
        </Content>
        {/* {shouldOpenModal && (
          <Modal
            title="Filtre os Criadores de Conteúdo"
            // modalIsOpen={() => cleanFilters(false)}
            setShouldOpenModal={() => setShouldOpenModal(false)}
          >
            <div className="socialMediaFilter">
              <h3>Onde quer vender?</h3>
            </div>
            <div className="regionFilter">
              <div className="inputPlace">
                <select
                  name="state"
                  id="state"
                  value={stateSelectedRef.current ?? ""}
                  onChange={({ target }) => filterState(target.value)}
                >
                  <option value="" disabled selected>
                    Selecione Estado
                  </option>
                  {uf.length
                    ? uf.map((state) => (
                        <option value={state.nome}>{state.nome}</option>
                      ))
                    : null}
                </select>
                <select
                  name="city"
                  id="city"
                  value={citySelectedRef.current ?? ""}
                  onChange={({ target }) => {
                    handleCitySelected(target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Selecione Cidade
                  </option>
                  {city.length
                    ? city.map((item) => <option value={item}>{item}</option>)
                    : null}
                </select>
              </div>
            </div>
            {filterType === "segment" ? (
              <div className="segmentFilter">
                <h3>Qual audiência quer alcançar?(Selecione 03)</h3>
                <div
                  className="inputPlace"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minHeight: 250,
                  }}
                >
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    name="select"
                    className="multiSelect"
                    options={segment}
                    values={[]}
                    placeholder="Selecione uma opção"
                    onChange={(value) => handleIdSegment(value)}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      setBackgroundColor="var(--brightpurple)"
                      setColor="var(--white)"
                      onClick={() => showInfluencers()}
                      style={{ width: 180, marginRight: 20 }}
                    >
                      Buscar
                    </Button>
                    <Button
                      setBackgroundColor="transparent"
                      setColor="var(--brightpurple)"
                      onClick={() => cleanFilters(true)}
                      style={{
                        width: 150,
                        border: "1px solid var(--brightpurple)",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "var(--lightpurple)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent";
                      }}
                    >
                      Limpar filtros
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </Modal>
        )}
        <Content>
          <div className="searchPlace">
            <div className="inputPlace">
              <BsSearch className="searchIcon"></BsSearch>
              <input
                placeholder="Digite aqui o nome do influenciador que deseja encontrar"
                onChange={(event) => filterFromInput(event.target.value)}
              />
            </div>
            <div
              className="btnFilter"
              onClick={() => setShouldOpenModal(true) && cleanFilters(true)}
            >
              <button>ENCONTRE O CRIADOR DE CONTEÚDO IDEAL</button>
            </div>
          </div>
          <InfluencersList>{renderInfluencers(listToShow)}</InfluencersList>
        </Content> */}
      </Main>
    </Container>
  );
};
export default Dashboard;
