import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 82%;
  padding: 50px 30px;

  div {
    /* width: 90%; */
    label {
      display: flex;
      font-family: Inconsolata;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 8px;
      width: 100%;
    }

    input, textarea, select {
      border: 2px solid var(--black);
      border-radius: 5px;
      margin-bottom: 6px;
      padding: 12px;
      width: 100%;
    }

    textarea {
      height: 80px;
    }

    p {
      margin-bottom: 8px;
      text-align: left;
      width: 100%;
    }
  }

  @media (max-width: 850px) {
    width: calc(100% - 155px);
  }

  @media (max-width: 650px) {
    & > div:nth-of-type(1) {
      width: 100% !important;
    }
  }

  @media (max-width: 490px) {
    div > label {
      font-size: 18px;
    }
  }

  @media (max-width: 465px) {
    padding: 40px 20px;
  }
  
  @media (max-width: 425px) {
    width: calc(100% - 135px);
  }
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 8px 0 30px;
  width: 100%;

  @media (max-width: 700px) {
    button {
      margin-inline: 0;
      width: 100% !important;
    }
  }

  @media (max-width: 550px) {
    .step {
      margin-bottom: 6px;
      width: 100% !important;
    }
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  border: 1px solid var(--graycard);
  border-radius: 10px;
  margin-top: 20px;
  margin-inline: auto;
  padding: 20px;
  width: 70%;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    width: 100% !important;
  }
`;

export const DivStep = styled.div`
  background-color: ${props => props.active ? "var(--brightpurple)" : "transparent"};
  border: ${props => props.active ? "none" : "1px solid var(--graycard)"};
  border-radius: 50%;
  color: ${props => props.active ? "var(--white)" : "var(--black)"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inconsolata;
  font-size: 22px;
  font-weight: 600;
  margin-right: 10px;
  height: 40px;
  width: 40px;
`;