import styled from "styled-components";

export const Content = styled.div`
  width: 18%;
  min-width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: fixed;
  /* z-index: 1; */
  height: 100dvh;
  left: ${(props) => (props.visibleSidebar ? "0" : "-100%")};
  transition: 0.7s linear;
  box-shadow: 0 1px 8px 1px var(--gray);

  .headerModal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--white);
    padding: 14px 0;
    height: 100px;

    svg {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }

    .logoDiv {
      overflow-y: hidden;
    }

    img {
      max-width: 180px;
      min-width: 50px;
      height: 40px;
      @media (max-width: 500px) {
        max-width: 90px;
      }
    }

    button {
      border: none;
      background-color: var(--purple);
      font-size: 20px;
      font-weight: 600;
      margin-right: 20px;
      color: var(--mediumgray);
      cursor: pointer;
      :hover {
        color: var(--black);
      }
    }

    @media (max-width: 390px) {
      padding: 0;
    }
  }

  .contentModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 20px 0;
    height: 100%;
    ul {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      li {
        background-color: transparent;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin: 6px 8px;
        color: var(--black);
        font-family: Inconsolata;
        font-weight: 600;
        font-size: 17px;
        padding: 10px;

        &.active {
          background-color: var(--yellow);
        }

        cursor: pointer;
        @media (max-width: 500px) {
          font-size: 20px;
          font-weight: 600;
        }

        @media (max-width: 425px) {
          font-size: 14px;
          padding: 0 8px;
          text-align: center;
          margin-bottom: 12px
        }
      }
      @media (max-width: 425px) {
        margin-top: 0;
      }
    }
  }

  .logout {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;

    svg, img {
      margin-right: 8px;
    }
  }

  @media(max-width: 425px) {
    min-width: 135px;
  }
`;
