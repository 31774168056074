import React from "react";
import { Container, DivRow } from "./styles";
import { BsSearch } from "react-icons/bs";
import Button from "../Button/Button";
import { useAuth } from "../../providers/auth";

const InputSearch = ({ value, name, placeholder, handleChange, submit, bgColor }) => {
  const { auth } = useAuth
  return (
    <Container bgColor={bgColor} logged={auth ? true : false}>
      <DivRow>
        <BsSearch size={28} fill="#B8B8B8" />
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={({ target }) => handleChange(name, target.value)}
        />
        <Button
          setBackgroundColor={"var(--yellow)"}
          setColor={"var(--black)"}
          style={{ border: "none", marginBottom: 0 }}
          onClick={() => submit()}
        >
          Buscar
        </Button>
      </DivRow>
    </Container>
  );
};

export default InputSearch;
