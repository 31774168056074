import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Main,
  Content,
  Solicitations,
  DivRow,
  DivColumn,
  Card,
  DivButton,
} from "./styles";
import { useClient } from "../../providers/user";
import { useAuth } from "../../providers/auth";
import api from "../../services";
import Logo from "../../assets/user.webp";
import { formatDate, onlyNumber } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { toReal } from "../../utils/currency";
import { alterStatusCampanha, alterStatusSolicitation } from "../../providers/solicitation";
import { toast } from "react-toastify";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import ImgUser from "../../assets/user.webp";
import InputSearch from "../../components/InputSearch";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { FaArrowLeft } from "react-icons/fa";
import { useDebounce } from "../../hooks/useDebounce";

const SolicitationsList = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const pageSolicitations = useRef(1);
  const pageCandidaturas = useRef(1);
  const pageSolicitationsFinished = useRef(1);
  const pageCandidaturasCompleted = useRef(1);
  const totalPagesSolicitations = useRef();
  const totalPagesCandidaturas = useRef();
  const totalPagesSolicitationsFinished = useRef();
  const totalPagesCandidaturasCompleted = useRef();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [visibleSidebar, setVisibleSidebar] = useState(true);
  const [showSolicitations, setShowSolicitations] = useState(0);
  const [newSolicitations, setNewSolicitations] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [solicitationsAccepted, setSolicitationsAccepted] = useState([]);
  const [candidaturas, setCandidaturas] = useState([]);
  const [solicitationsFinished, setSolicitationsFinished] = useState([]);
  const [solicitationsRefused, setSolicitationsRefused] = useState([]);
  const [filterSolicitations, setFilterSolicitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [sectionActive, setSectionActive] = useState("Em Andamento");
  const [solicitationSelected, setSolicitationSelected] = useState();
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [mySolicitation, setMySolicitation] = useState(false);
  const [solicitationConcluded, setSolicitationConcluded] = useState(false);
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome: "",
    valor: 0,
    descricao: "",
  });

  useEffect(() => {
    if (clientId) {
      if (showSolicitations === 0) {
        setCandidaturas([]);
        getCandidaturas();
      } else {
        setSolicitationsAccepted([]);
        setSolicitationsFinished([]);
        getSolicitationsAccepted();
      }
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [searchValue, showSolicitations, clientId]);

  // useEffect(() => {
  //     getSolicitations();
  //     window.addEventListener("scroll", handleScroll);
  // }, [])

  const getSolicitations = async (_page = 1) => {
    const response = await api
      .get(`api/v3/solicitacao_servico_cli/${clientId}/`, {
        params: {
          page: _page,
          status: "Nova Oferta",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        const total =
          response.data.count % 10 === 0
            ? response.data.count / 10
            : Math.floor(response.data.count / 10) + 1;
        // totalPages.current = total;

        if (_page === 1) {
          setNewSolicitations(response.data.results);
          // page.current = 1;
        } else {
          setNewSolicitations((old) => [...old, ...response.data.results]);
          // setPage(page);
          // page.current = _page;
        }
      });

    return response;
  };

  const getSolicitationsAccepted = async (_page = 1) => {
    let params = {
      page: _page ? _page : pageSolicitations.current,
      status: showSolicitations === 1 
        ? "Nova Oferta,Em Andamento,Pago Aguardando Serviço" 
        : showSolicitations === 2
        ? "Concluído,recusado"
        : null,
    };

    const response = await api
      .get(`api/v3/solicitacao_servico_cli/${clientId}/`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        const total =
          response.data.count % 10 === 0
            ? response.data.count / 10
            : Math.floor(response.data.count / 10) + 1;
        if(showSolicitations === 0) {
          totalPagesSolicitations.current = total;
          pageSolicitations.current = _page;
          setSolicitationsAccepted((prev) => [...prev, ...response.data.results]);
        } else if (showSolicitations === 2) {
          totalPagesSolicitationsFinished.current = total;
          pageSolicitationsFinished.current = _page;
          setSolicitationsFinished((prev) => [...prev, ...response.data.results]);
        }
          
      });

    return response;
  };

  const getCandidaturas = async (_page = 1) => {
    let params = {
      page: _page ? _page : pageCandidaturas.current,
      id_cliente: clientId,
      status: showSolicitations === 0 ? "0,1" : "2", // Pendente, Aprovado ou Rejeitado
    };

    const response = await api
    .get(`api/v1/candidaturas/`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    })
    .then((response) => {
      const total =
        response.data.count % 10 === 0
          ? response.data.count / 10
          : Math.floor(response.data.count / 10) + 1;
      
      if (showSolicitations === 0) {
        totalPagesCandidaturas.current = total;
        pageCandidaturas.current = _page;

        setCandidaturas((prev) => [
          ...prev,
          ...response.data.results,
        ]);
      } else if (showSolicitations === 2) {
        const _results = [...response.data.results];
        totalPagesCandidaturasCompleted.current = total;
        pageCandidaturasCompleted.current = _page;

        setSolicitationsFinished((old) => [
          ...old,
          ..._results,
        ]);
      }
    })

    return response
  }

  const handleScroll = useCallback(
    useDebounce((e) => {
      const isBottomReached =
        window.innerHeight + e.target.documentElement.scrollTop + 1 >=
        e.target.documentElement.scrollHeight;

      if (isBottomReached) {
        // Verifica se está mostrando solicitações ou candidaturas
        if (showSolicitations === 0) {
          if (pageCandidaturas.current < totalPagesCandidaturas.current) {
            getCandidaturas(pageCandidaturas.current + 1);
          }
        } else if (showSolicitations === 1) {
          // Verifica se existem mais páginas de solicitações e candidaturas abertas
          if (pageSolicitations.current < totalPagesSolicitations.current) {
            getSolicitationsAccepted(pageSolicitations.current + 1);
          }
        } else if (showSolicitations === 2) {
          // Verifica se existem mais páginas de solicitações e candidaturas encerradas
          if (
            pageSolicitationsFinished.current <
            totalPagesSolicitationsFinished.current
          ) {
            getSolicitationsAccepted(pageSolicitationsFinished.current + 1);
          }
          if (
            pageCandidaturasCompleted.current <
            totalPagesCandidaturasCompleted.current
          ) {
            getCandidaturas(pageCandidaturasCompleted.current + 1);
          }
        }
      }
    }, 300), // 300ms debounce
    [
      showSolicitations,
      pageSolicitations,
      totalPagesSolicitations,
      pageCandidaturas,
      totalPagesCandidaturas,
      pageSolicitationsFinished,
      totalPagesSolicitationsFinished,
      pageCandidaturasCompleted,
      totalPagesCandidaturasCompleted,
    ]
  );


  const handleSelectSolicitation = (
    solicitation,
    mySolicitation,
    concluded
  ) => {
    setSolicitationSelected(solicitation);
    // setShouldOpenModal(true);
    setMySolicitation(mySolicitation);
    if (concluded) {
      setSolicitationConcluded(true);
    } else {
      setSolicitationConcluded(false);
    }
    if (!solicitation.campanha) {
      setStep(step + 1);
    } else {
      setStep(3);
    }
  };

  const toggleSidebar = () => {
    setVisibleSidebar(!visibleSidebar);
  };

  const handleAlterStatus = async (status) => {
    setLoadingStatus(true);
    try {
      const result = await alterStatusSolicitation(
        solicitationSelected.id,
        status,
        auth
      );
      if (result) {
        if (status === "Em Andamento") {
          openWhatsApp();
          setStep(1);
        } else if (status === "recusado") {
          setStep(1);
        }
        await getSolicitationsAccepted();
        await getCandidaturas();
        setLoadingStatus(false);
        // setShouldOpenModal(false);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setLoadingStatus(false);
    }
  };

  const handleAlterStatusCampanha = async (status) => {
    setLoadingStatus(true);
    try {
      const result = await alterStatusCampanha(
        solicitationSelected.id,
        status,
        auth
      );
      if (result) {
        if (showSolicitations === 0) {
          const solicitations = candidaturas.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setCandidaturas(solicitations)
        } else if (showSolicitations === 1) {
          const solicitations = solicitationsAccepted.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setSolicitationsAccepted(solicitations)
        } else if (showSolicitations === 2) {
          const solicitations = solicitationsFinished.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setSolicitationsFinished(solicitations)
        }

        setStep(1);
        setLoadingStatus(false);
        // setShouldOpenModal(false);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setLoadingStatus(false);
    }
  }

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const renderSolicitations = (solicitation) => {

    return solicitation.map((solicitation, index) => (
      <div className="influencerSolicitation" key={index}>
        <div className="solicitation">
          <img
            src={
              solicitation.influencidor.profile_picture
                ? solicitation.influencidor.profile_picture
                : Logo
            }
            alt="client-profilepic"
            // onClick={() =>
            //     handleNavigate(
            //         solicitation,
            //         `${solicitation.cliente.id}${solicitation.influencidor.id}${solicitation.id}`
            //     )
            // }
          />
          <div className="column">
            <h6 className="creator">{solicitation.influencidor.nome}</h6>
            <p className="cidade">
              {solicitation.influencidor.cidade}
              {solicitation.influencidor.estado
                ? `, ${solicitation.influencidor.estado}`
                : ""}
            </p>
            <div className="row">
              {solicitation.influencidor.tiktok ? (
                <FaTiktok
                  size={24}
                  onClick={() => openTikTok(solicitation.influencidor.tiktok)}
                  style={{ marginRight: 6 }}
                />
              ) : null}
              {solicitation.influencidor.instagram ? (
                <FaInstagram
                  size={26}
                  onClick={() =>
                    openInstagram(solicitation.influencidor.instagram)
                  }
                />
              ) : null}
            </div>
          </div>
        </div>

        <div
           className="solicitation"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {solicitation.titulo ? (
            <p style={{ fontSize: 18, fontWeight: 600 }}>{solicitation.titulo}</p>
          ) : solicitation.campanha_detalhes && solicitation.campanha_detalhes.nome_campanha ? (
            <p style={{ fontSize: 18, fontWeight: 600 }}>{solicitation.campanha_detalhes.nome_campanha}</p>
          ) : null}
          {solicitation.status === "Nova Oferta" &&
          solicitation.solicitante === 0 ? (
            <p className="solicitationStatus newSolicitation">
              Status: Aguardando Resposta do Influencer
            </p>
          ) : solicitation.status === "Nova Oferta" &&
            solicitation.solicitante === 1 ? (
            <p className="solicitationStatus newSolicitation">
              Status: Aguardando Sua Resposta
            </p>
          ) : solicitation.status === "Em Andamento" ? (
            <p className="solicitationStatus aceita">Status: Aceita</p>
          ) : solicitation.status === "recusado" ? (
            <p className="solicitationStatus recusado">Status: Recusado</p>
          ) : (
            <p className="solicitationStatus aceita">
              Status: {solicitation.status}
            </p>
          )}

          <Button
            setBackgroundColor={"var(--yellow)"}
            setShadow={'var(--graycard)'}
            style={{marginRight: 0}}
            onClick={() => handleSelectSolicitation(solicitation, solicitation.solicitante === 0 ? true : false, false)}
          >
            Ver Proposta
          </Button>
        </div>
        {/* {solicitation.status === "Pago Aguardando Serviço" ? (
                    solicitation.influencidor && solicitation.influencidor.whatsapp ? (
                        <button
                            className="primary"
                            style={{
                                cursor: "pointer",
                                padding: "10px 12px",
                            }}
                        >
                            <a
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                    padding: "10px 12px",
                                    textDecoration: "none",
                                }}
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=${onlyNumber(solicitation.influencidor.whatsapp)}`}
                            >
                                Falar com Creator
                            </a>

                        </button>
                    ) :
                        <div className="emptySpace"></div>
                ) : solicitation.status === "Nova Oferta" &&
                    solicitation.solicitante === 1 ? (
                    <Button
                        setBackgroundColor={'var(--yellow)'}
                        onClick={() => handleSelectSolicitation(solicitation, false, false)}
                    >
                        Ver Proposta
                    </Button>
                ) : solicitation.status === "Concluído" ? (
                    <Button
                        setBackgroundColor={'var()'}
                        onClick={() => handleSelectSolicitation(solicitation, true, true)}
                    >
                        Ver Proposta
                    </Button>
                ) : solicitation.status === "Em Andamento" ? (
                    <button
                        className="primary"
                        onClick={() => handleNavigate(
                            solicitation,
                            `${solicitation.cliente.id}${solicitation.influencidor.id}${solicitation.id}`
                        )}
                    >
                        Pagar
                    </button>
                ) : (
                    <div className="emptySpace"></div>
                )} */}
      </div>
    ));
  };

  function handleDateInicio(value) {
    try {
      return format(new Date(value), "dd/MM/yyyy");
    } catch (error) {
      return value;
    }
  }

  const handleNavigate = (creator) => {
    const url = `/influencerdetails/${creator.id}`;

    // Armazenar o estado no sessionStorage
    sessionStorage.setItem("influencer", JSON.stringify(creator));

    const fullUrl = `${window.location.origin}/#${url}`;
    window.open(fullUrl, "_blank");
  };

  const handlePortifolio = (url) => {
    window.open(url, "_blank");
  }

  const openTikTok = (tiktok) => {
    const _tiktok = tiktok && tiktok[0] === "@" ? tiktok.slice(1) : tiktok
    const url = `https://www.tiktok.com/@${_tiktok}`;
    window.open(url, "_blank");
  };

  const openInstagram = (instagram) => {
    const _instagram = instagram && instagram[0] === "@" ? instagram.slice(1) : instagram
    const url = `https://www.instagram.com/${_instagram}`;
    window.open(url, "_blank");
  };

  const openWhatsApp = () => {
    if (solicitationSelected && solicitationSelected?.influencidor?.whatsapp) {
      const url = `https://wa.me/${onlyNumber(solicitationSelected.influencidor.whatsapp)}`

      window.open(url, "_blank");
    }
  }

  return (
    <Container>
      <Main visibleSidebar={visibleSidebar}>
        <DivBetween style={{ marginBottom: 14 }}>
          <Title>Central de Propostas</Title>
          <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage>
        </DivBetween>
        {step === 1 ? (
          <>
            <InputSearch
              bgColor={"var(--white)"}
              name="search"
              value={searchValue}
              placeholder="Pesquisar nome da proposta"
              handleChange={handleSearchChange}
              submit={getSolicitationsAccepted}
            />
            <Content>
              <div className="solicitation">
                <button
                  className={showSolicitations === 0 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(0);
                  }}
                >
                  <h2>Minhas Campanhas</h2>
                </button>
                
                <button
                  className={showSolicitations === 1 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(1);
                  }}
                >
                  <h2>Ofertas Creators</h2>
                </button>

                <button
                  className={showSolicitations === 2 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(2);
                  }}
                >
                  <h2>Histórico</h2>
                </button>
                {loading ? <span className="loader"></span> : null}
              </div>

              {/* {showSolicitations === 1 ? (
                            <div className="solicitationsType">
                                <button
                                    className={sectionActive === "Em Andamento" ? "active" : ""}
                                    onClick={() => {
                                        setSectionActive("Em Andamento");
                                    }}
                                >
                                    <span>Em Andamento</span>
                                </button>
                                <button
                                    className={
                                        sectionActive === "Concluído" ? "active" : ""
                                    }
                                    onClick={() => {
                                        setSectionActive("Concluído");
                                    }}
                                >
                                    <span>Finalizadas</span>
                                </button>
                                <button
                                    className={sectionActive === "recusado" ? "active" : ""}
                                    onClick={() => {
                                        setSectionActive("recusado");
                                    }}
                                >
                                    <span>Recusadas</span>
                                </button>
                            </div>
                        ) : null} */}

              {showSolicitations === 0 ? (
                <Solicitations>
                  {renderSolicitations(candidaturas)}
                </Solicitations>
              ) : showSolicitations === 1 ? (
                <Solicitations>
                  {renderSolicitations(solicitationsAccepted)}
                </Solicitations>
              ) : showSolicitations === 2 ? (
                <Solicitations>
                  {renderSolicitations(solicitationsFinished)}
                </Solicitations>
              ) : null}
            </Content>
          </>
        ) : step === 2 && solicitationSelected ? (
          <Card>
            <FaArrowLeft
              size={22}
              onClick={() => setStep(step - 1)}
              style={{ cursor: "pointer" }}
            />
            <DivColumn style={{ margin: "16px 8px 8px" }}>
              <h2>{solicitationSelected.titulo}</h2>

              <p>
                Publicado:{" "}
                {solicitationSelected.criacao
                  ? formatDate(solicitationSelected.criacao)
                  : ""}
              </p>
              <DivColumn style={{ marginTop: 20, marginBottom: 8 }}>
                <h3>Descrição do pacote:</h3>
                <p>{solicitationSelected.descricao_servico}</p>
              </DivColumn>

              {solicitationSelected && solicitationSelected.sobre_influencer ? (
                <DivColumn style={{ marginTop: 20, marginBottom: 10 }}>
                  <h2>Por que eu?</h2>
                  <p>{solicitationSelected.sobre_influencer}</p>
                </DivColumn>
              ) : null}

              <p>
                Valor:{" "}
                {solicitationSelected.valor
                  ? toReal(+solicitationSelected.valor)
                  : ""}
              </p>

              <DivRow>
                <DivRow className="imageProfile" style={{ width: "60%" }}>
                  <img
                    src={
                      solicitationSelected.influencidor.profile_picture
                        ? solicitationSelected.influencidor.profile_picture
                        : Logo
                    }
                    height={70}
                    width={70}
                    alt="Foto do influencer"
                  />
                  <DivColumn>
                    <h3>{solicitationSelected.influencidor.nomecompleto}</h3>
                    <p className="cidade">
                      {solicitationSelected.influencidor.cidade}
                      {solicitationSelected.influencidor.estado
                        ? `, ${solicitationSelected.influencidor.estado}`
                        : null}
                    </p>
                    <DivRow>
                      {solicitationSelected.influencidor.tiktok ? (
                        <FaTiktok
                          size={22}
                          onClick={() =>
                            openTikTok(solicitationSelected.influencidor.tiktok)
                          }
                          style={{ marginRight: 6 }}
                        />
                      ) : null}
                      {solicitationSelected.influencidor.instagram ? (
                        <FaInstagram
                          size={26}
                          onClick={() =>
                            openInstagram(
                              solicitationSelected.influencidor.instagram
                            )
                          }
                        />
                      ) : null}
                    </DivRow>
                  </DivColumn>
                </DivRow>
                <DivColumn className="buttonsCreator" style={{ width: "40%" }}>
                  {solicitationSelected?.influencidor?.link_portifolio ? (
                  <DivButton
                    setBackgroundColor={"var(--white)"}
                    setShadow={"var(--black)"}
                    onClick={() =>
                      handlePortifolio(solicitationSelected?.influencidor?.link_portifolio)
                    }
                  >
                    Ver Portifólio
                  </DivButton>
                  ) : null}

                  {solicitationSelected?.influencidor?.whatsapp ? (
                    <DivButton
                      setBackgroundColor={"var(--white)"}
                      setShadow={"var(--black)"}
                    >
                      <a
                        href={`https://wa.me/${onlyNumber(
                          solicitationSelected.influencidor.whatsapp
                        )}?text=Ol%C3%A1!%20Vi%20seu%20portf%C3%B3lio%20na%20plataforma%20Boraver%20e%20gostaria%20de%20saber%20mais%20sobre%20como%20voc%C3%AA%20trabalha.%20Pode%20me%20explicar%3F`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--black)",
                        }}
                      >
                        Enviar Mensagem
                      </a>
                    </DivButton>
                  ) : null}
                </DivColumn>
              </DivRow>
              {!mySolicitation && solicitationSelected.status === "Nova Oferta" ? (
                <DivRow
                  className="actionsSolicitation"
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    justifyContent: "center",
                  }}
                >
                  <DivButton
                    setBackgroundColor={"var(--yellow)"}
                    disabled={loadingStatus}
                    onClick={() => handleAlterStatus("Em Andamento")}
                    style={{ width: "48%" }}
                  >
                    Aceitar Proposta
                  </DivButton>
                  <DivButton
                    setBackgroundColor={"var(--graycard)"}
                    setShadow={"var(--black)"}
                    disabled={loadingStatus}
                    onClick={() => handleAlterStatus("recusado")}
                    style={{ width: "48%" }}
                  >
                    Recusar
                  </DivButton>
                </DivRow>
              ) : null
              // <DivRow
              //   style={{
              //     marginTop: 30,
              //     marginBottom: 30,
              //     justifyContent: "center",
              //   }}
              // >
              //   <DivButton
              //     setBackgroundColor={"var(--white)"}
              //     disabled={loadingStatus}
              //     onClick={() =>
              //       handleNavigate(
              //         solicitationSelected,
              //         `${solicitationSelected.cliente.id}${solicitationSelected.influencidor.id}${solicitationSelected.id}`
              //       )
              //     }
              //   >
              //     Mais detalhes
              //   </DivButton>
              // </DivRow>
              }
            </DivColumn>
          </Card>
        ) : step === 3 && solicitationSelected ? (
          <Card>
            <FaArrowLeft
              size={22}
              onClick={() => setStep(1)}
              style={{ cursor: "pointer" }}
            />
            <DivColumn style={{ margin: "16px 8px 8px" }}>
              <h2>{solicitationSelected?.campanha_detalhes?.nome_campanha}</h2>

              <p>
                Data Início:{" "}
                {solicitationSelected?.campanha_detalhes?.data_inicio
                  ? formatDate(solicitationSelected?.campanha_detalhes?.data_inicio)
                  : ""}
              </p>

              {solicitationSelected && solicitationSelected.justificativa ? (
                <DivColumn style={{ marginTop: 20, marginBottom: 10 }}>
                  <h2>Por que eu?</h2>
                  <p>{solicitationSelected.justificativa}</p>
                </DivColumn>
              ) : null}

              <p>
                Valor:{" "}
                {solicitationSelected?.campanha_detalhes?.valor
                  ? toReal(+solicitationSelected?.campanha_detalhes?.valor)
                  : ""}
              </p>

              <DivRow>
                <DivRow className="imageProfile" style={{ width: "60%" }}>
                  <img
                    src={
                      solicitationSelected.influencidor.profile_picture
                        ? solicitationSelected.influencidor.profile_picture
                        : Logo
                    }
                    height={70}
                    width={70}
                    alt="Foto do influencer"
                  />
                  <DivColumn>
                    <h3>{solicitationSelected.influencidor.nomecompleto}</h3>
                    <p className="cidade">
                      {solicitationSelected.influencidor.cidade}
                      {solicitationSelected.influencidor.estado
                        ? `, ${solicitationSelected.influencidor.estado}`
                        : null}
                    </p>
                    <DivRow>
                      {solicitationSelected.influencidor.tiktok ? (
                        <FaTiktok
                          size={22}
                          onClick={() =>
                            openTikTok(solicitationSelected.influencidor.tiktok)
                          }
                          style={{ marginRight: 6 }}
                        />
                      ) : null}
                      {solicitationSelected.influencidor.instagram ? (
                        <FaInstagram
                          size={26}
                          onClick={() =>
                            openInstagram(
                              solicitationSelected.influencidor.instagram
                            )
                          }
                        />
                      ) : null}
                    </DivRow>
                  </DivColumn>
                </DivRow>
                <DivColumn className="buttonsCreator" style={{ width: "40%" }}>
                  {solicitationSelected?.influencidor?.link_portifolio ? (
                  <DivButton
                    setBackgroundColor={"var(--white)"}
                    setShadow={"var(--black)"}
                    onClick={() =>
                      handlePortifolio(solicitationSelected?.influencidor?.link_portifolio)
                    }
                  >
                    Ver Portifólio
                  </DivButton>
                  ) : null}

                  {solicitationSelected?.influencidor?.whatsapp ? (
                    <DivButton
                      setBackgroundColor={"var(--white)"}
                      setShadow={"var(--black)"}
                    >
                      <a
                        href={`https://wa.me/${onlyNumber(
                          solicitationSelected.influencidor.whatsapp
                        )}?text=Ol%C3%A1!%20Vi%20seu%20portf%C3%B3lio%20na%20plataforma%20Boraver%20e%20gostaria%20de%20saber%20mais%20sobre%20como%20voc%C3%AA%20trabalha.%20Pode%20me%20explicar%3F`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--black)",
                        }}
                      >
                        Enviar Mensagem
                      </a>
                    </DivButton>
                  ) : null}
                </DivColumn>
              </DivRow>
            </DivColumn>

            {!mySolicitation && solicitationSelected.status === "Pendente" ? (
                <DivRow
                  className="actionsSolicitation"
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    justifyContent: "center",
                  }}
                >
                  <DivButton
                    setBackgroundColor={"var(--yellow)"}
                    disabled={loadingStatus}
                    onClick={() => handleAlterStatusCampanha(1)}
                    style={{ width: "48%" }}
                  >
                    Aceitar Proposta
                  </DivButton>
                  <DivButton
                    setBackgroundColor={"var(--graycard)"}
                    setShadow={"var(--black)"}
                    disabled={loadingStatus}
                    onClick={() => handleAlterStatusCampanha(2)}
                    style={{ width: "48%" }}
                  >
                    Recusar
                  </DivButton>
                </DivRow>
              ) : null}
          </Card>
        ) : null}
      </Main>
    </Container>
  );
};

export default SolicitationsList;
