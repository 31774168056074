import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const PromotionContainer = styled.div`
  background-color: ${props => props.logged ? 'var(--white-50)' : 'var(--yellow)'};
  text-align: center;
  padding: 10px 0 20px;
  width: ${props => props.logged ? "82%" : "100%"};
  ${props => props.logged ? "margin-left: auto;" : null}

  p {
    color: var(--black);
  }

  strong {
    color: var(--black);
    text-decoration: underline;
  }

  @media (max-width: 700px) {
    ${props => props.logged ? "width: calc(100% - 155px);" : null}
  }

  @media (max-width: 425px) {
    ${props => props.logged ? "width: calc(100% - 135px);" : null}
    ${props => props.logged ? "font-size: 14px;" : null }
  }

  @media (max-width: 400px) {
    ${props => props.logged ? "font-size: 12px;" : null}
  }
`;

export const Content = styled.div`
  width: 90vw;
  /* background-color: tomato; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;

  @media (max-width: 768px) {
  }

  .left-side {
    /* background-color: blue; */
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 550px) {
      margin-bottom: 30px;
      width: 90%;
    }

    .logoDiv {
      width: 100%;
      display: flex;
      align-items: center;
      /* background-color: turquoise; */
      img {
        /* background-color: coral; */
        width: 200px;
        padding: 10px 0;
      }

      /* @media (min-width: 1024px) {
        max-width: 20%;
      } */
    }
    .mainInformation {
      color: var(--black);
      font-family: Inconsolata;
      h4 {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }

    .sociais {
      h4 {
        font-family: Inconsolata;
        font-size: 28px;
        font-weight: 700;
        line-height: 29px;
      }
      button {
        background-color: transparent;
        border: none;
      }

      svg {
        font-size: 38px;
      }
    }
  }

  .menu {
    font-family: Inconsolata;
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
    width: 30%;

    h4 {
      margin-bottom: 12px;
    }
    
    a, button {
      color: var(--black-50);
      display: flex;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-decoration: none;
    }

    button {
      border: none;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      padding-bottom: 0;
    }

    button:hover {
      box-shadow: none;
    }

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 550px) {
      margin-bottom: 30px;
      width: 90%;
    }
  }

  .right-side {
    color: var(--black);
    /* background-color: tan; */
    display: flex;
    flex-direction: column;
    font-family: Inconsolata;
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
    width: 30%;
    
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: right;
    }

    button {
      font-weight: 600;
      margin-left: 5px;
      cursor: pointer;
      background: transparent;
      color: var(--white);
      border: none;
      font-size: 18px;
      /* text-align: center; */
      :hover {
        color: var(--blue);
      }
    }

    h4 {
      margin-bottom: 12px;
    }

    a {
      color: var(--black-50);
      display: flex;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-decoration: none;
    }

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 550px) {
      margin-bottom: 30px;
      width: 90%;
    }
  }
`;

export const DivCenter = styled.div`
  background-color: ${props => props.logged ? 'var(--white-50)' : 'var(--yellow)'};
  display: flex;
  justify-content: center;
  padding: 20px 0 0;
  width: ${props => props.logged ? "82%" : "100%"};
  ${props => props.logged ? "margin-left: auto;" : null}

  a, button {
    color: var(--black);
    text-decoration: none;
  }

  button {
    border: none;
  }

  @media (max-width: 700px) {
    ${props => props.logged ? "width: calc(100% - 155px);" : null}
  }

  @media (max-width: 425px) {
    ${props => props.logged ? "width: calc(100% - 135px);" : null}
    ${props => props.logged ? "font-size: 14px;" : null}
  }
`;

// export const Container = styled.div`
//   max-width: 100vw;
//   display: flex;
//   justify-content: center;
//   background-color: var(--purple);
//   padding: 20px 0px;
// `

// export const Content = styled.div`
//   width: 85vw;
//   display: flex;
//   justify-content: ${(props) => (props.isContactUs ? "center" : "space-between")};
//   align-items: center;
//   color: var(--white);

//   .left-side{
//     display: flex;
//     div{
//       margin-left: 10px;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//     }
//     h4{
//       font-size: 20px;
//       font-weight: 600;
//     }
//     p{
//       font-size: 14px;
//       margin: 5px 15px 0px 0px;
//     }
//   }

//   .right-side{
//     button{
//       font-weight: 600;
//       margin-left: 5px;
//       cursor: pointer;
//       background: transparent;
//       color: var(--white);
//       border: none;
//       font-size: 18px;
//       :hover{
//         color: #bc00dd;
//       }
//     }
//   }

//   @media (max-width: 1023px){
//     width: 95vw;
//   }
// `
