import styled from "styled-components";

export const Container = styled.div`
    background-color: ${(props) => props.bgColor ? props.bgColor : 'transparent'};
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    height: 70px;
    width: 60%;
    max-width: 650px;

    @media (max-width: 720px) {
        width: 100%;
    }
`;

export const DivRow = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 8px;

    input {
        border: none;
        background-color: transparent;
        color: #B8B8B8;
        font-family: Inconsolata;
        font-size: 22px;
        font-weight: 400;
        margin-left: 10px;
        width: 80%;
    }

    @media (max-width: 550px) {
        svg {
            font-size: 22px;
        }
        input {
            font-size: 16px;
        }
    }
`;