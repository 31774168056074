import React from "react";
import {
  Card,
  YellowBar,
  DivIMage,
  DivRow,
  Creator,
  Cidade,
  Sobre,
  Description,
} from "./styled";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import Button from "../Button/Button";

const CardCreatorLarge = ({ creator }) => {

  const handleNavigation = () => {
    const url = `/influencerdetails/${creator.id}`;

    // Armazenar o estado no sessionStorage
    sessionStorage.setItem("influencer", JSON.stringify(creator));

    const fullUrl = `${window.location.origin}/#${url}`;
    window.open(fullUrl, "_blank");
  };

  return (
    <Card>
      <YellowBar />
      <DivRow>
        <DivIMage>
          <img src={creator.profile_picture} />
        </DivIMage>
        {creator?.tiktok ? <FaTiktok size={28} /> : null}
        {creator?.instagram ? <FaInstagram size={28} /> : null}
      </DivRow>
      <Creator>{creator.nome}</Creator>
      <Cidade>
        {creator.cidade}
        {creator.estado ? `, ${creator.estado}` : ""}
      </Cidade>
      <Sobre>Sobre mim</Sobre>
      <Description>
        {creator.sobre
          ? creator.sobre
          : "Sou um creator UGC apaixonado por produzir material autêntico e envolvente. Com experiência em diversas plataformas digitais, crio posts e vídeos que capturam a essência das marcas. Minha abordagem é inovadora e focada em conectar-se genuinamente com o público."}
      </Description>
      <Button
        setBackgroundColor={"var(--white)"}
        onClick={() => handleNavigation()}
      >
        Ver Perfil
      </Button>
    </Card>
  );
};

export default CardCreatorLarge;
