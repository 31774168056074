import { useEffect, useState } from 'react';
import LogoPic from '../../assets/Logotipo_boraver.png';
import { useHistory, Link } from "react-router-dom";
import Button from "../Button/Button";
import {BsBellFill} from 'react-icons/bs';
import { Container, Content, MobileMenu } from "./styles";
import { useAuth } from '../../providers/auth';
import { useClient } from '../../providers/user';
import { FaTimes } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import Modal from '../Modal/Modal';
import ImgUser from '../../assets/user.webp';



const Header = ({isAuth, visibleSidebar}) => {
  
  const { setAuth, auth } = useAuth()
  
  const { unreadNotifications, setUserId, userData } = useClient()
  
  const history = useHistory()
  var url = window.location.href;

  const [shouldOpenModal, setShouldOpenModal] = useState(false)
  const [linkConvite, setLinkConvite] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const partesDaURL = url.split("/");

    if (partesDaURL.includes("influencerDetails") | partesDaURL.includes("notifications") && !auth) {
      setLinkConvite(true)
    }
  }, []);

  const handleNavigation = (path) => {
    return history.push(path)
  }

  const handleLogout = () => {
    setAuth()
    setUserId()
    localStorage.clear()
    return history.push('/')
  }

  const handleLogoClick = () => {
    if(isAuth){
      return history.push('/dashboard')
    }
    if(!isAuth){
      return history.push('/')
    }
  }

  const renderRightSide = () => {
    if (isAuth){
      return(
        <div className="right-side">
          <div className='notifications-place'>
            {/* <BsBellFill className="bell-icon" size={25} onClick={() => handleNavigation("/clientProfile")}></BsBellFill> */}
            <Button 
              setBackgroundColor="var(--purple)"
              setColor="var(--white)"
              onClick={() => handleNavigation("/clientProfile")}
              style={{marginBottom: 0}}
            >Perfil</Button>
            {
              unreadNotifications > 0
              ? <div className='unread-notifications'>{1}</div>
              :null
            }
          </div>
          <span style={{
            margin: '0px 5px'
          }}>
            |
          </span>
          <Button 
            setBackgroundColor="var(--purple)"
            setColor="var(--white)"
            onClick={() => handleLogout()}
            style={{marginBottom: 0}}
          >Sair</Button>
          {url.includes('dashboard') ? ( 
            <img src={userData && userData.profile_picture ? userData.profile_picture : ImgUser} alt='Imagem do usuário' height={50} width={50}/>
          ) : null}
        </div>
      )
    } 
    if(!isAuth && !linkConvite){
      return(
        <div className="right-side">
          <Button
            setBackgroundColor="transparent"
            setColor="var(--black)" 
            onClick={() => handleNavigation("/login")}
            style={{border: 'none'}}
          >Login
          </Button>
          <Button 
            setBackgroundColor="var(--yellow)"
            setColor="var(--black)"  
            onClick={() => handleNavigation("/signup")}
          >Cadastre-se
          </Button>
          <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <GiHamburgerMenu size={26} fill="var(--black)" />
          </div>
          <MobileMenu isMenuOpen={isMenuOpen}>
            <div className="close-button" onClick={() => setIsMenuOpen(false)}>
              <FaTimes size={26} fill="var(--black)" />
            </div>
            <Link to='/' onClick={() => setIsMenuOpen(false)}>Home</Link>
            <Link to='lista-creators' onClick={() => setIsMenuOpen(false)}>Encontrar Creators</Link>
            <a href="https://linktr.ee/boraverapp" target="_blank" rel="noopener noreferrer" onClick={() => setIsMenuOpen(false)}>Virar um Creator</a>
          </MobileMenu>
        </div>
      )
    }
  }

  return(
    // <div>
    //   {
    //     shouldOpenModal &&
    //     (
    //       <Modal isMenu={true} setShouldOpenModal={setShouldOpenModal}>
    //         <ul>
    //           <li onClick={() => handleNavigation("/clientProfile")}>Perfil</li>
    //           <li onClick={() => handleNavigation("/dashboard")}>Buscar<br /> Influenciador</li>
    //           <li onClick={() => handleNavigation("/clientProfile")}>Meu histórico</li>
    //         </ul>
    //       </Modal>
    //     )
    //   }
      <Container>
        <Content>
          <div className="left-side">
            {/* {
              !!isAuth && <div><GiHamburgerMenu onClick={() => toggleSidebar()} /></div>
            } */}
            <div className='logoDiv'>
              <img src={LogoPic} alt="logo-boraver" onClick={() => handleLogoClick()}/> 
            </div>
          </div>
          <div className="links">
            <Link to='/'>Home</Link>
            <Link to='lista-creators'>Encontrar Creators</Link>
            <a href="https://linktr.ee/boraverapp" target="_blank" rel="noopener noreferrer">Virar um Creator</a>
          </div>
          {renderRightSide()}
        </Content>
      </Container>
    // </div>
  )
}
export default Header