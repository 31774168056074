import { createGlobalStyle } from "styled-components";

// font-family: 'Montserrat', sans-serif;
// font-family: 'Noto Serif HK', serif;
// font-family: 'Poppins', sans-serif;

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    overflow-x: hidden;
  }

  :root{
    --purple: #3C2E54;
    --purple-50: #720FCA;
    --brightpurple: #7B2CBF;
    --lightpurple: #EBE3F2;
    --alert: #F4B551;
    --lightalert: #F4B551AB;
    --yellow: #F7B700;
    --lightyellow: #FFF6DE;
    --white: #FFF;
    --white-50: #F4F4F4;
    --black: #000000;
    --black-50: #2F251B;
    --blue: #00B4D8;
    --dark-blue: #1C0C2B;
    --vanilla: #F9F8E6;
    --red: #D00000;
    --softgray: #F8F9FA;
    --gray: #D3D3D3;
    --gray-50: #9D9D9D;
    --mediumgray: #ADB5BD;
    --darkgray: #495057;
    --graycard: #d6d6d6;
    --lavender: #DEC9E9;
    --green: #38B000;
    --lightgreen: #34D399;
    background-color: #FFF6DE;
    font-family: Inconsolata;
  }

  button, textarea {
    font-family: Inconsolata;
  }

  button + button{
    margin-left: 1rem;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    width: 100%;
    max-width: 576px;
    background: var(--white);
    position: relative;
    border-radius: 0.25rem;
  }
  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 560px) {
    .ReactModalPortal > div > div {
      width: 90% !important;
    }
  }
`;
