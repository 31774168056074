import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo-boraver-preto-sem-fundo.png";
import { Container, Content, DivCenter, PromotionContainer } from "./styles";
import { useAuth } from "../../providers/auth";
import { FaInstagram } from "react-icons/fa";
import Button from "../Button/Button";

const Footer = ({ isContactUs }) => {
  const { auth } = useAuth();
  const history = useHistory();
  const [linkConvite, setLinkConvite] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const partesDaURL = url.split("/");

    if (partesDaURL.includes("influencerDetails") | partesDaURL.includes("notifications") && !auth) {
      setLinkConvite(true);
    }
  }, []);

  const handleNavigation = (path) => {
    return history.push(path);
  };

  const handleinstagram = () => {
    window.open("https://www.instagram.com/boraverapp?igsh=MThyZmo5bHhjcnZ4ag==", "_blank")
  }

  return (
    <>
      {!linkConvite && !auth ? (
        <Container>
          <Content isContactUs={isContactUs}>
            <div className="left-side">
              <div className="logoDiv">
                <img src={Logo} alt="Logo Boraver UGC" />
              </div>
              <div className="mainInformation">
                <h4>Conectando creators e marcas</h4>
                {/* <p>Av. Litorânea, N 100, Calhau, São Luís-MA</p>
                <p>CNPJ: 45.611.049/0001-19</p> */}
              </div>
              <div className="sociais">
                <button onClick={() => handleinstagram()}>
                  <FaInstagram />
                </button>
              </div>
            </div>
            <div className="menu">
              <h4>Menu</h4>
              <Button
               setBackgroundColor={'transparent'} 
               onClick={() => handleNavigation('/login')}
               >Minha Conta</Button>
              <Button
               setBackgroundColor={'transparent'}
               onClick={() => handleNavigation('/lista-creators')}
              >Encontrar Creators</Button>
              <a href="https://linktr.ee/boraverapp" target="_blank" rel="noopener noreferrer">Virar um Creators</a>
              <a href="#">Novidades</a>
            </div>
            {!isContactUs && (
              <div className="right-side">
                <h4>Links</h4>
                {/* <a href="#">Minha Conta</a> */}
                <a href="https://boraverapp.com/termos-uso.html" target="_blank" rel="noopener noreferrer">Política de Uso</a>
                <a href="https://boraverapp.com/politica-privacidade.html" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
                <a href="#">FAQ</a>
                {/* <div>
                  Ainda tem dúvidas?{" "}
                  <button onClick={() => handleNavigation("/contactus")}>
                    Fale conosco
                  </button>
                </div> */}
              </div>
            )}
          </Content>
        </Container>
      ) : (
        <DivCenter logged={auth}>
          <a href="" target="_blank" rel="noopener noreferrer">Termos de Uso</a> | <a href="" target="_blank" rel="noopener noreferrer">Politica de Privacidade</a>
        </DivCenter>
      )}

      <PromotionContainer logged={auth}>
        <p>
          Developed by{" "}
          <a href="https://devari.com.br" target="_blank">
            <strong>Devari Tecnologia</strong>
          </a>
        </p>
      </PromotionContainer>
    </>
  );
};
export default Footer;
