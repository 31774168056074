import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Logo from "../../assets/user.webp";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import {
  ClientProfileDetails,
  Container,
  Content,
  Button,
} from "./styles";
import api from "../../services";
import EditIcon from "../../assets/icon.svg";
import { uploadFile } from "../../services/cliente/upload";
import { updateProflePicture } from "../../services/cliente/updateProfilePicture";
import { regioes } from "../../utils/helpers";
import Modal from "../../components/Modal/Modal";

const ClientProfile = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const history = useHistory();
  const [newSolicitations, setNewSolicitations] = useState([]);
  const [filterSolicitations, setFilterSolicitations] = useState([]);
  // const isPictureLoading = useRef(false)
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [shouldOpenModalFilter, setShouldOpenModalFilter] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [uf, setUf] = useState([]);
  const [citySelected, setCitySelected] = useState();
  const [stateSelected, setStateSelected] = useState();
  const [city, setCity] = useState([]);
  const [idSegment, setIdSegment] = useState();
  const [segment, setSegment] = useState([]);

  useEffect(() => {
    showSegment();
    showStates();
  }, []);

  const showSegment = async () => {
    const response = await api
      .get(`/api/v1/segmentos/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        setSegment(response.data);
      });
    return response;
  };

  const handleParamsObject = (_page) => {
    const params = {
      page: _page,
      ativo: true,
    };

    if (idSegment === 0 && stateSelected === "") {
      return params;
    } else if (idSegment !== 0 && stateSelected !== "" && citySelected !== "") {
      return {
        ...params,
        segmento_influence: idSegment,
        cidade: citySelected,
        estado: stateSelected,
      };
    } else if (idSegment === 0 && stateSelected !== "" && citySelected === "") {
      return {
        ...params,
        estado: stateSelected,
      };
    } else if (idSegment !== 0 && stateSelected === "") {
      return {
        ...params,
        segmento_influence: idSegment,
      };
    }
  };

  const showStates = () => {
    let ufs = [];
    regioes.regioes.map((item) => ufs.push(...item.estados));
    setUf(ufs);
  };

  const filterFromInput = (e) => {
    if (e === "") {
      setFilterSolicitations([]);
      return;
    }
    const newArr = newSolicitations.filter((item) =>
      item.influencidor.nome.toLowerCase().includes(e.toLowerCase())
    );
    setFilterSolicitations(newArr);
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    // isPictureLoading.current = true;
    setIsPictureLoading(true);

    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    const url = await uploadFile("cliente", formData);

    const response = await updateProflePicture(clientId, url);

    setUserData(response);

    setIsPictureLoading(false);
    // isPictureLoading.current = false;
  };

  useEffect(() => {
    if (!auth) {
      return history.push("/");
    }
    // getSolicitations();
    return;
  }, []);

  const filterState = (_estado) => {
    setStateSelected(_estado);
    setCity(uf.find((e) => e.nome === _estado).cidades);
    const newArr = newSolicitations.filter((item) => {
      if (item.influencidor.estado) {
        return item.influencidor.estado
          .toLowerCase()
          .includes(_estado.toLowerCase());
      }
    });
    setFilterSolicitations(newArr);
  };

  const handleCitySelected = (city) => {
    setCitySelected(city);
    const newArr = newSolicitations.filter((item) => {
      if (item.influencidor.cidade) {
        return item.influencidor.cidade
          .toLowerCase()
          .includes(city.toLowerCase());
      }
    });
    setFilterSolicitations(newArr);
  };

  const handleIdSegment = (id) => {
    setIdSegment(id);
    const newArr = newSolicitations.filter((item) => {
      if (item.segmento.id) {
        return item.segmento.id === +id;
      }
    });
    setFilterSolicitations(newArr);
  };

  const cleanFilters = (status) => {
    setFilterType("");
    setStateSelected("");
    setCitySelected("");
    setFilterSolicitations([]);
    setIdSegment();
    setShouldOpenModalFilter(false);
  };

  

  return (
    <Container>
      {shouldOpenModalFilter && (
        <Modal
          title="Filtre os Influencers"
          modalIsOpen={() => cleanFilters(false)}
          setShouldOpenModal={() => setShouldOpenModalFilter(false)}
        >
          {filterType !== "social_media" &&
            filterType !== "zone" &&
            filterType !== "segment" &&
            filterType !== "qtd_followers" ? (
            <div className="socialMediaFilter">
              <h3>Filtrar por:</h3>
              <Button
                setBackgroundColor="var(--darkgray)"
                setColor="var(--white)"
                onClick={() => setFilterType("zone")}
              >
                Região
              </Button>
              <Button
                setBackgroundColor="var(--darkgray)"
                setColor="var(--white)"
                onClick={() => setFilterType("segment")}
              >
                Segmento
              </Button>
            </div>
          ) : null}
          {filterType === "zone" ? (
            <div className="regionFilter">
              <h3 onClick={() => cleanFilters(true)}>Voltar</h3>
              <div className="inputPlace">
                <select
                  name="state"
                  id="state"
                  value={stateSelected ?? ""}
                  onChange={({ target }) => filterState(target.value)}
                >
                  <option value="" disabled selected>
                    Selecione Estado
                  </option>
                  {uf.map((state) => (
                    <option value={state.nome}>{state.nome}</option>
                  ))}
                </select>
                <select
                  name="city"
                  id="city"
                  value={citySelected ?? ""}
                  onChange={({ target }) => {
                    handleCitySelected(target.value);
                    setShouldOpenModalFilter(false);
                  }}
                >
                  <option value="" disabled selected>
                    Selecione Cidade
                  </option>
                  {city.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <Button
                  setBackgroundColor="var(--brightpurple)"
                  setColor="var(--white)"
                  onClick={() => cleanFilters(true)}
                >
                  Limpar filtros
                </Button>
              </div>
            </div>
          ) : null}
          {filterType === "segment" ? (
            <div className="segmentFilter">
              <h3 onClick={() => cleanFilters(true)}>Voltar</h3>
              <div className="inputPlace">
                <select
                  value={idSegment ?? 0}
                  name="segment"
                  id="segment"
                  onChange={({ target }) => {
                    handleIdSegment(target.value);
                    setShouldOpenModalFilter(false);
                  }}
                >
                  <option value={0} disabled>
                    Selecione Segmento
                  </option>
                  {segment.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                <Button
                  setBackgroundColor="var(--brightpurple)"
                  setColor="var(--white)"
                  onClick={() => cleanFilters(true)}
                >
                  Limpar filtros
                </Button>
              </div>
            </div>
          ) : null}
        </Modal>
      )}

      {/* <Header isAuth /> */}
      <Content>
        {!!userData && (
          <ClientProfileDetails>
            <input
              type="file"
              accept="image/*"
              id="contained-button-file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <div style={{ cursor: "pointer" }} className="image-container">
                <img
                  src={
                    userData.profile_picture
                      ? userData.profile_picture.replace(
                        "base64:",
                        "data:image/png;base64,"
                      )
                      : Logo
                  }
                  alt="client-profilepic"
                />
                {isPictureLoading ? (
                  <Spinner positioned={true} />
                ) : (
                  <img className="logo" src={EditIcon} />
                )}
              </div>
            </label>

            <div>
              <h2>
                {userData.nome} {userData.sobrenome}
              </h2>
              <a>Alterar nome</a>
              <h4>{userData.email}</h4>
              <a>Alterar email</a>
              <h4>{userData.celular}</h4>
              <span>Alterar contato</span>
              <h4>{userData.cpf_cnpj}</h4>
              <span>CPF/CNPJ</span>
            </div>
          </ClientProfileDetails>
        )}
      </Content>
      {/* <Footer /> */}
    </Container>
  );
};
export default ClientProfile;
