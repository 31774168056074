import React, { useEffect } from "react";
import { Container, Title } from "./styles";
import Checked from "../../assets/icon-check.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/Button/Button";

const ConfirmRegister = () => {
  const history = useHistory();

  useEffect(() => {
    // Adiciona o script do Google Tag Manager
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-11016750802";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11016750802');
        `;
    document.body.appendChild(script2);

    // Limpeza dos scripts quando o componente for desmontado
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <Container>
      <Title>Cadastro realizado com sucesso!</Title>
      <img
        src={Checked}
        height={60}
        width={60}
        alt="Ícone de confirmação de cadastro"
      />
      <Button
        setBackgroundColor={"var(--white)"}
        setShadow={"var(--black)"}
        onClick={() => history.push("/login")}
      >
        Fazer Login
      </Button>
    </Container>
  );
};

export default ConfirmRegister;
