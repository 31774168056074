import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  DivBetween,
  Title,
  DivImage,
  Content,
  DivRow,
} from "./styles";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import ImgUser from "../../assets/user.webp";
import InputSearch from "../../components/InputSearch";
import api from "../../services";
import CardCreatorLarge from "../../components/CardCreatorLarge";
import Spinner from "../../components/Spinner";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { DivCenter } from "../ListCreators/styles";
import Button from "../../components/Button/Button";

const VitrineCreators = () => {
  const { auth } = useAuth();
  const { userData } = useClient();
  const totalPages = useRef();
  const page = useRef(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [influencers, setInfluencers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (searchValue === "") getInfluencers();
  }, [searchValue]);

  const getInfluencers = async (_page = 1) => {
    try {
      setLoading(true);

      let _params = {
        page: _page,
        ativo: true,
        search: searchValue,
      };

      const response = await api.get("api/v1/influencers/list-influencers/", {
        params: _params,
      });

      if (response) {
        const total =
          response.data.count % 10 === 0
            ? response.data.count / 10
            : Math.floor(response.data.count / 10) + 1;
        totalPages.current = total;

        setInfluencers(response.data.results);
        page.current = _page;
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const handlePagination = (_page) => {
    if (_page < 1 || _page > totalPages.current) return;

    getInfluencers(_page);
  };

  return (
    <Container logged={auth ? true : false}>
      <DivBetween>
        <Title>Vitrine de Creators</Title>
        <DivImage>
          <img
            src={
              userData && userData.profile_picture
                ? userData.profile_picture
                : ImgUser
            }
            alt="Imagem do usuário"
            height={60}
            width={60}
          />
        </DivImage>
      </DivBetween>
      <Content>
        <InputSearch
          bgColor={"var(--white)"}
          name="search"
          value={searchValue}
          placeholder="Buscar Creator..."
          handleChange={handleSearchChange}
          submit={getInfluencers}
        />

        <DivRow>
          {influencers.length > 0
            ? influencers.map((item) => <CardCreatorLarge creator={item} />)
            : null}
        </DivRow>

        <DivCenter style={{ marginBottom: 10 }}>
          <Button
            setBackgroundColor={"var(--white)"}
            setColor={"var(--back)"}
            disabled={loading || page.current === 1}
            onClick={() => handlePagination(page.current - 1)}
          >
            <FaArrowLeft size={10} /> Anterior
          </Button>
          <Button
            setBackgroundColor={"var(--white)"}
            setColor={"var(--back)"}
            disabled={loading || page.current === totalPages.current}
            onClick={() => handlePagination(page.current + 1)}
          >
            Próximo <FaArrowRight size={10} />
          </Button>
        </DivCenter>
        {loading ? <Spinner /> : <div style={{ height: 80 }}></div>}
      </Content>
    </Container>
  );
};

export default VitrineCreators;
